import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const w01EAT01EATBasis = () => (
  <Layout>
    <SEO title="w01EAT01EATBasis" />

    <h1 id="ueberschrift">Eigenorganisation Spezial</h1>
    <h2>Eigenorganisation, Arbeitsteilung, Teamarbeit &emsp;  ©&nbsp;1992</h2>

      <p>
        Kontakt:&ensp;
        <a href="mailto:iarna@bastet-project.de?subject=Anfrage zu w01EAT01EATBasis&amp;body=Bitte senden Sie so viele Daten wie möglich (z.B.: gewünschter Ort, Tag, Anzahl der Teilnehmer, Altersgruppe, etc.)." title="per E-Mail kontaktieren" style={{ fontSize: "1em" }}>iarna@bastet-project.de</a>
      </p>

      <p>
        Spätestens wenn die Frage aufkommt, wie "xy" vergessen werden konnte oder warum "z" vergeblich gesucht wird,
        ploppt zumindest für einen Bruchteil einer Sekunde der Wunsch nach einer besseren Organisation auf.
        <br />
        Doch wie lässt sich das bewerkstelligen? Man tut doch schon so viel!
      </p>
      <p>  
        *Eigenorganisation* ist mehr als nur die Autoschlüssel ans Schlüsselbrett zu hängen oder einen Termin
        sogleich in den Terminkalender einzutragen. Und *Eigenorganisation* ist auch weniger als eben dieses.
      </p>
      <p>
        Meist ist es einfacher etwas zu verstehen, das erlebt oder zumindest angeschaut werden kann.
        Aus diesem Grund bietet dieser Kurs kleine Rollenspiele und Arbeitskreise, die Ihnen helfen,
        die Hürden einer "perfekten Organisation" zu erkennen.
        So können Sie Methoden entwickeln, welche Ihnen helfen können, besser organisiert den Alltag zu genießen.
      </p>
      <p style={{fontStyle: "italic", textDecoration: "underline"}}>
        Mitmachkurs
      </p>
      <p>
        für alle Altersgruppen<br/>
        Platzbedarf: 1 Tisch pro Teilnehmer + 1 Tisch + ca. 2x2m Freiraum extra<br/>
        Materialbedarf: keiner
      </p>
      <p>
        Privatstunden möglich<br/>
        Platzbedarf: 1 Tisch
      </p>
      <p>
        + keine besonderen Voraussetzungen nötig +
      </p>

    <Link to="/fdml_1a_aktuelles-klartext/" title="Detaillierte Informationen zum Bereich Veranstaltungen &amp; mehr" >Aktuelle Termine</Link>
      <p></p>
  </Layout>
              )

              export default w01EAT01EATBasis
